import logo from './logo.svg';
import './App.css';
import Menu from './Menu.js';
import GenImage from './GenImage.js';
import Home from './Home.js';
import { Router, Routes, Route, useLocation, BrowserRouter } from 'react-router-dom';
import Create from './Create.js';
import CreateNew from './CreateNew.js';
import Loader from './Loader.js';
import Info from './Info.js';
import SingleImage from './SingleImage.js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Map from "./Map.js"
function App() {
  const location = useLocation();
  return (
    <div className="App light">
        <Routes location={location}>
        <Route path="/" element={<CreateNew />} />

        </Routes>
    </div>
  );
}

export default App;
