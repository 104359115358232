import React, {useState, useRef} from 'react';
import Replicate from "replicate";
import axios from "axios";
import Loader from "./Loader";
import { AiOutlineReload } from 'react-icons/ai';
import InputEmoji from "react-input-emoji";
import { Helper } from "./Hooks/Helper";
import Location from "./LocationAPI";
import { NavLink } from "react-router-dom";

function isEmpty(str) {
    return !str.trim().length;
}
function CreateNew() {

    const [image, updateImg] = useState("https://replicate.delivery/pbxt/zid7xYwPCErRL91kBNpP3rtWEFiTsXw6JnoWEpRuLgcni2AE/out-0.png");
    const [prompt, updatePrompt] = useState();
    const [negPrompt, updateNegPrompt] = useState();
    const [caption, updateCaption] = useState("");
    const [author, updateAuthor] = useState("");
    const [loading, setLoading] = useState(false);
    const [isGenerated, setGenerated] = useState(false);
    const [reset, setReset] = useState(false);
    const [isSubmitted, setSubmit] = useState(false);
    const [isError, setError] = useState(false);
    const [isAdvanced, setAdvanced] = useState(false);
    const [isGuide, setGuide] = useState(false);
    const [isNSFW, setNSFW] = useState(false);
    const [imgId, setImgId] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    const [idGenerated, setIdGenerated] = useState("");
    const [location, setLocation] = useState(null);
    const [igIsChecked, setIgIsChecked] = useState(true)
    const [postId, setPostId] = useState("");
    const [igURL, setIGURL] = useState("");
    const [igLimitReached, setIgLimitReached] = useState(false);
    const inputRef = useRef();
    const resetEverything = () => {
        setGenerated(false);
        setSubmit(false);
        setError(false);
        setImageLoaded(false);
        updateImg("");
        updatePrompt("");
        updateNegPrompt("");
        updateCaption("");
    }
    const checkHandler = () => {
        setIgIsChecked(!igIsChecked)
    }
    const generate = async prompt => {
        if(!isEmpty(prompt)){
        setLoading(true)
        const result = await axios.post(`https://api.jakobseeder.pro`, {
            prompt : "jakobsitter "+prompt,
         }).then((response) => {
            const json = response.data;
            console.log('json', json);
            if(json.status == "COMPLETED"){
                updateImg(json.output[0].image);
                //setIdGenerated(json._id);
                setImgId(json.output[0].image)
                setNSFW(json.output[0].nsfw[0])
                setLoading(false)
                setGenerated(true)
                setImageLoaded(true)
                setReset(true)
                setTimeout(() => setReset(false), 100);
            }
            if(json.status == "FAILED") {
                setLoading(false)
                setError(true)
            }
        })
        .catch((error) => {
        console.log(error);
            setError(true)
        });
    }
    }

    function raspi(iid) {
        axios.get(`https://raspi.jakobseeder.pro/post?url=`+"https://api.jakobseeder.pro/images/"+iid+".png").then((response) => {
            const json = response.data;
            console.log('json', json);
        })
        .catch((error) => {
        console.log(error);
        });
    }
    const submit = async caption => {
        setLoading(true)
        const result = await axios.post(`https://api.jakobseeder.pro/setcaption`, {
            prompt : "jakobsitter "+prompt,
            caption : caption,
            img_id: imgId,
            isIg: igIsChecked,
            nsfw: isNSFW,
            author: author
         }).then((response) => {
            const json = response.data;
            console.log('json', json);
            if(json.ig_limit == true){
                setIgLimitReached(true)
            }
            if(json.success == true){
                raspi(json.img_id)
            if(igIsChecked){
                setIGURL(json.ig_url)
            }
            setPostId(json.cockpit._id)
            setLoading(false)
            }
        })
        .catch((error) => {
        console.log(error);
        });

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          generate(prompt);
        }
    }
    const handleSubmit = (event) => {
        if(!isEmpty(caption) && !isEmpty(author)){
        if(!isSubmitted){
            setSubmit(true)
            submit(caption);
        } else {
            return false;
        }
        } else {
            return false;
        }
    }
    return(
        <div className="create_page">
            
            <div className={isGuide ? "guide visible" : "guide"}>
                When prompting it is important to be detailed and specific. The model do not understand metaphores like humans do, so for best results try to describe exactly what you want in clear language. Adding keywords such as "8k, hi res, dslr, RAW photo" might help the model generate more photorealistic images. 
                <br />
                <br />
                Negative prompts can be added in the advanced settings section to help the model understand what you do not want depicted in the image. For instance, if you want photorealistic results, you can add keywords such as "3d, worst quality, low quality, extra fingers, mutated hands, poorly drawn hands, poorly drawn face" to make sure the model does not generate a cartoonish image.
            </div>
            {!loading & !isSubmitted & !isGenerated & !isError ? 
            <>
            <div className="form_outer">
                <div className="form">
                    <div className="pre_prompt">jakobsitter...</div>
                    <input className="prompt_input"  type="text" placeholder="...type prompt here" onChange={e=>updatePrompt(e.target.value)} value={prompt} autoFocus onKeyDown={handleKeyDown}></input>
                    <button onClick={e=>generate(prompt)} className="submit_button">Generate</button>
                    <div className="expand" style={{"display": "none"}} alt="Advanced Settings" onClick={() => setAdvanced(!isAdvanced)}>
                    {isAdvanced ? "-" : "+"}
                    </div>
                    <div className="expand" style={{"display": "none"}} onMouseEnter={() => setGuide(true)} onMouseLeave={() => setGuide(false)}>
                    ?
                    </div>
                   
                </div>
                <div className={isAdvanced ? "form_advanced visible form" : "form_advanced form"}>
                    <input className="input"  type="text" placeholder="negative prompt" onChange={e=>updateNegPrompt(e.target.value)} value={negPrompt} autoFocus onKeyDown={handleKeyDown}></input>
                </div>
            </div>
            </>
            : null
            }
            {loading & !isError ?
            <div className="loading">
                <Loader genLoader={true} />
            </div>
            : null
            }
            {imageLoaded & !isSubmitted & !isError ?
            <div className={isNSFW ? "image_container nsfw" : "image_container"}>
            <img src={image} className="generated_image"></img>
            <br /><br />
            <div className="caption_form">
                <input className="caption_input" type="text" placeholder="Caption*" onChange={e=>updateCaption(e.target.value)} value={caption}></input>
                <div class="break"></div>
                <input className="caption_input" type="text" placeholder="Author*" onChange={e=>updateAuthor(e.target.value)} value={author}></input>
                <button onClick={e=>handleSubmit()} className={!isEmpty(caption) && !isEmpty(author) ? "submit_button" : "submit_button inactive"}>Send to Frame</button>
                <div className="expand" onClick={e=>resetEverything()}>
                    Reset
                </div>
                <div className="break"></div>
                {isNSFW ? "This image has been marked as NSFW and cannot be posted to Instagram." : 
                <div className="checkbox_container">
                <input type="checkbox" id="checkbox" checked={igIsChecked} onChange={checkHandler} />
                <div className="checkbox_label">Post to Instagram</div>
                </div>
                }
            </div>
            </div>
            : null
            }
            {isSubmitted & !loading ?
            <div className="submitted">
                <div className="submitted_text">Submission successful!</div>
                {igLimitReached && 
                                <div>Your image was posted to this platform, but not to Instagram because the daily API limit has been reach. Try again later.<br /><br /></div>
                }
                <a href={"https://jakobseeder.pro/item/"+postId} className="">
                <div className="link">Post Page</div>
                </a>
                {igURL &&
                <a href={igURL} target="_blank" className="">
                <div className="link">Instagram Link</div>
                </a>
                }
                <a href="#" onClick={e=>resetEverything()}>
                <div className="link">Reset</div>
                </a>
            </div>
            : null}
            {isError ? 
            <div className="error">
                Error! Your creation was probably caught by the NSFW filter. Try again with a different prompt.
                <br /><br />
                <center>
                <div className="expand errorreset" onClick={e=>resetEverything()}>
                    Reset
                </div>
                </center>
            </div>
            : ""}
        </div>
    );
}

export default CreateNew;